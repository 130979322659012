import React, { useEffect } from "react";
import SEO from "../components/SEO";
import PrivacySection from "../components/partials/home-2022/PrivacySection";
import AlternatedFeaturesSection from "@src/components/AlternatedFeaturesSection";
import BrandLoveSection from "@src/components/partials/about/BrandLoveSection";
import { graphql, useStaticQuery } from "gatsby";
import CTASection from "@src/components/CTASection";
import HeroSection from "@src/components/partials/teacher/HeroSection";
import { logEvent } from "@src/utils/logClient";
import DojoIslandsBanner from "@src/components/partials/dojo-islands/DojoIslandsBanner";

const IndexPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        page_home_2022 {
          features
          privacy_link
          cta_button_url
          cta_background_image_url
        }
        page_careers {
          brand_love_images
          brand_love_tweets
        }
        page_teachers {
          grid_features_items
        }
      }
    }
  `);
  const {
    directus: {
      page_home_2022: { features, privacy_link, cta_button_url, cta_background_image_url },
      page_careers: { brand_love_images, brand_love_tweets },
      page_teachers: { grid_features_items },
    },
  } = data;

  const iconColors = ["aqua", "tangerine", "kiwi"];

  const heroSectionProps = {
    heading: "directus.page_teachers.heading",
    tagline: "directus.page_teachers.tagline",
    taglineHighlight: "directus.page_teachers.tagline_highlight",
    featuresHeading: "directus.page_teachers.grid_features_title",
    featuresReviews: "directus.page_teachers.grid_features_reviews",
  };

  const AlternatedFeaturesSectionProps = {
    invert_desktop_order: true,
    title: "",
    text: "",
    content: features.map((feature, index) => ({
      ...feature,
      title: `directus.page_home_2022.features.title_${index + 1}`,
      text: `directus.page_home_2022.features.text_${index + 1}`,
    })),
    page: "page_home_2022",
    home_variation: true,
  };
  const BrandLoveSectionProps = {
    brand_love_title: "directus.page_home_2022.brand_love_title",
    brand_love_text: "",
    brand_love_images,
    brand_love_tweets,
    brand_love_cta_text: "directus.page_careers.brand_love_cta_text",
    three_liner: false,
    translate: true,
    home_variation: true,
    BGColor: false,
  };
  const PrivacySectionProps = {
    privacy_title: "directus.page_home_2022.privacy_title",
    privacy_subtitle: "directus.page_home_2022.privacy_subtitle",
    privacy_link,
    privacy_link_label: "directus.page_home_2022.privacy_link_label",
  };
  const CTASectionProps = {
    cta_title: "directus.page_home_2022.cta_title",
    cta_button_text: "directus.page_home_2022.cta_button_text",
    cta_background_image_url,
    cta_advice: "",
    button_log_event_name: "",
    params: {},
    home_variation: true,
    teacherSignup: true,
  };
  useEffect(() => {
    logEvent({
      eventName: "web.external.teachers.page_view",
      eventValue: location.href,
    });
  }, []);

  return (
    <>
      {/* <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-652437177"></script>
      </Helmet>
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `
            window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-652437177');
            `,
          },
        ]}
      /> */}
      <SEO title="directus.page_teachers.SEO_title" description="directus.page_teachers.SEO_description" />
      <HeroSection {...heroSectionProps} />
      <AlternatedFeaturesSection {...AlternatedFeaturesSectionProps} />
      <DojoIslandsBanner utmParam="teacherPage" />
      <BrandLoveSection {...BrandLoveSectionProps} />
      <PrivacySection {...PrivacySectionProps} />
      <CTASection {...CTASectionProps} />
    </>
  );
};
export default IndexPage;
